import {createAction, props} from '@ngrx/store';

export const loadLocations = createAction(
  '[Location List API] Load Location list',
);

export const loadLocationsSuccess = createAction(
  '[Location List API] Location list loaded success',
  props<{ list: any[] }>()
);

export const loadLocationsFailure = createAction(
  '[Location List API] Location list loaded failure',
  props<{ error: string }>()
);