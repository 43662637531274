import {combineLatest, map, Observable} from 'rxjs';
import {AppState} from 'src/app/state/app.state';
import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {TaxCalculationService} from '@zdIOTPlatform/zen-tax';
import {categoriesSelector, getCatNameFromId,} from '../state/products/product.selector';
import {getParcelCharge, getProductPricingData, selectTaxDetails,} from '../state/config/config.selector';
import {IParcelCharge} from '../models/kioskUiSettings.model';
import {DINETYPE} from '../constants';

@Injectable({ providedIn: "root" })
export class TheKioskOrderService {
  availableActionToPrint = false;
  isAutoRemoveReceiptPrint = false;
  isSwitchTable = false;
  tax = new TaxCalculationService();
  constructor(private store: Store<AppState>) {}
  calculateTaxAndParcelCharges(
    products: any[],
    obj?: {
      [x: string]: any;
      dineType: any;
    }
  ) {
    const storeId = sessionStorage.getItem("location");
    const body = {
      products: products,
      storeId: storeId,
      orderSource: "iotzen-pos",
      dineType: obj?.dineType,
    };

    let table: any, orderId: string, dborder: any;

    if (obj?.dineType === "table") {
      body["tableNo"] = obj?.tableNo;
      table = obj?.table;
      dborder = table?.["aProperties"]?.["orderData"];
      orderId = dborder?.["_id"];
    }

    return new Observable((s) => {
      this.isAutoRemoveReceiptPrint = true;
      this.prepareOrder(products, table, orderId, dborder, obj?.dineType).then(
        (order) => {
          order && s.next(order);
        }
      );
    });
  }

  createId() {
    return Date.now().toString(16);
  }

  prepareOrder(
    products,
    table?,
    orderId?: string,
    dborder?,
    dineType?: string,
    _action?: string,
    _subOrderId?: string
  ) {
    return new Promise((resolve) => {
      // const taxData = await new Promise(r => this.store.select(selectTaxDetails).subscribe(r));
      this.store.select(selectTaxDetails);
      this.store.select(getProductPricingData);
      combineLatest([
        this.store.select(selectTaxDetails),
        this.store.select(getProductPricingData)
      ])
      .pipe(
        map(([tax, ProductPricingDetails]) => {
          return { tax, ProductPricingDetails };
        })
      ).subscribe(async ({ tax, ProductPricingDetails }) => {
        try {
          const taxData = tax;
          dineType = dineType ? dineType : DINETYPE.TABLE;

          // update the item in suborder
          const getSubOrder = (prods, len?: number) => {
            console.log("creating sub orders", prods, _action, len);
            return {
              _id: `subOrder_${this.createId()}`,
              updated: new Date(),
              items: prods.map((p) => {
                const metaInfo = p["all"]?.metaInfo;
                return {
                  price: metaInfo?.prices?.[dineType]
                    ? metaInfo.prices[dineType]
                    : metaInfo?.price,
                  name: metaInfo?.itemName,
                  status: "placed",
                  quantity: p?.["all"]?.qty,
                  all: p["all"],
                  _id: p["_id"],
                  assetsLinked: p?.["all"]?.productInfo?.assetsLinked,
                };
              }),
            };
          };

          // update the item in order and cal tax
          const getProducts = async (prods: any) => {
            return Promise.all(
              prods.map(async (p) => {
                const metaInfo = p?.["all"]?.metaInfo;
                return {
                  price: metaInfo?.prices?.[dineType]
                    ? metaInfo.prices[dineType]
                    : metaInfo?.price,
                  name: metaInfo?.itemName,
                  _id: p?._id,
                  cName: await new Promise((r) =>
                    this.store
                      .select(getCatNameFromId(metaInfo?.categoryIds))
                      .subscribe(r)
                  ),
                  all: p?.["all"],
                  assetsLinked: p?.["all"]?.productInfo?.assetsLinked,
                  prices: metaInfo?.prices,
                  quantity: p?.["quantity"],
                  isPriceIncludesTax: ProductPricingDetails?.taxIncluded,
                  tax: taxData
                    ?.map((m) => {
                      return m["assetsLinked"].includes(p?._id)
                        ? {
                            name: `${m.name}`,
                            percentage: Number(m.percentage),
                          }
                        : undefined;
                    })
                    .filter((x) => !!x),
                };
              })
            );
          };

          let order: any;
          if (orderId && dborder) {
            order = structuredClone(dborder);
            let pList = [...order?.["orderDetails"]?.["items"]];

            // handle updated item
            for (let i = 0; i < products.length; i++) {
              const p1 = products[i];

              const dup = pList.find((x) => x._id === p1._id);
              if (dup) {
                if (_action === "remove") {
                  dup.quantity -= p1.quantity; //= p1?.['all']?.qty
                }

                if (_action === "add") {
                  dup.quantity += p1.quantity;
                }

                if (_action === "update" && !table) {
                  dup.quantity = p1?.["all"]?.qty;
                }
              } else {
                pList.push(p1);
              }
            }
            // handle removed items
            if (!table) {
              pList = pList.filter((x) =>
                products.map((p) => p._id).includes(x._id)
              );
            }

            order["orderDetails"]["items"] = await getProducts(pList);

            //hanle sub order data
            if (table) {
              if (_action === "remove") {
                const clone = structuredClone(order["subOrderData"]);
                const currentRemovedItems = [];
                let copyItem = {};
                for (let i = 0; i < products.length; i++) {
                  const p1 = products[i];
                  const idx = clone.findIndex((s) => s._id === _subOrderId);
                  const item = clone[idx]["items"].find(
                    (p) => p1._id === p._id
                  );
                  copyItem = { ...item };
                  copyItem["quantity"] = p1?.quantity;
                  const ri = clone[idx]["removedItems"];
                  if (ri) {
                    const i = ri.find((r) => r._id === item._id);
                    if (!i) {
                      clone[idx]["removedItems"] = [...ri, item];
                    }
                  } else {
                    clone[idx]["removedItems"] = [item];
                  }

                  if (item) {
                    item["quantity"] = p1?.["all"]?.qty;
                  }
                  currentRemovedItems.push(copyItem);
                }
                order["subOrderData"] = clone;
                order["currentRemovedItems"] = currentRemovedItems;

                // print when sub order modified
                if ((this, this.isAutoRemoveReceiptPrint)) {
                  //   this.printerAndNotify(order, PRINT.T_ON_TABLE_SO_UPDATE)
                }
              } else {
                const so = getSubOrder(
                  products,
                  order?.["subOrderData"]?.length
                );
                if (_action === "add") {
                  const len = order?.["subOrderData"]?.length;
                  so["kId"] = len ? len + 1 : 1;

                  order["subOrderData"] = [...order["subOrderData"], so];
                }
                // print when new sub order added
                if (this.availableActionToPrint && !this.isSwitchTable) {
                  //   this.printerAndNotify(order, PRINT.T_ON_TABLE_SEND_KITCHEN);
                }
                this.isSwitchTable = false;
              }
            }

            if (_action === "update") {
              order["orderLifeCycle"] = "updated";
            }
          } else {
            const uProducts = await getProducts(products);
            let so;
            if (table) {
              const s = getSubOrder(products);
              s["kId"] = 1;
              so = [s];
            }
            order = {
              accountId: localStorage.getItem("accountId"),
              tableId: table?.["_id"],
              orderLifeCycle: "created",
              dineType: dineType,
              userId: localStorage.getItem("userId"),
              tableData: table
                ? {
                    _id: table["_id"],
                    tableNo: table["tableNo"],
                  }
                : undefined,
              storeId: sessionStorage.getItem("location"),
              orderId: `L-${this.createId()}`,
              orderStatus: "created",
              orderSource: "iotzen-pos",
              assetType: "order",
              orderDetails: {
                orderDate: Date.now(),
                items: uProducts,
              },
              payment: tax,
              subOrderData: so,
            };
          }

          // await taxCalculation();
          const pc: IParcelCharge = await new Promise((r) =>
            this.store.select(getParcelCharge).subscribe(r)
          );
          const getCatInfoByProductId = await new Promise((r) =>
            this.store
              .select(
                categoriesSelector({
                  store: sessionStorage.getItem("location"),
                })
              )
              .subscribe(r)
          );
          const o: any = this.tax?.taxCalculation(
            dineType,
            order["orderDetails"],
            ProductPricingDetails,
            pc,
            getCatInfoByProductId
          );
          order["payment"] = o?.payment;
          this.isAutoRemoveReceiptPrint = false;
          resolve(order);
        } catch (e) {
          this.isAutoRemoveReceiptPrint = false;
          console.error(e);
        }
      });
    });
  }
   checkQuantities(products) {
    const res = { status: true, statusMsg: '' };
    for (const obj of products) {
        if ((obj?.quantity > obj?.metaInfo?.quantity) && obj?.metaInfo?.isInventoryEnabled) {
            res.status = false;
            if (res?.statusMsg !== '') res.statusMsg += '<br>';
            res.statusMsg += `- ${obj.name} (Available Quantity: ${obj?.metaInfo?.quantity})`;
        }
    }
    if (!res.status) {
        res.statusMsg = 'Requested Quantity is Not Available:<br>' + res?.statusMsg;
    }
    return res;
 }

 modifyPayloadForAddOns(cartItems,disableTaxCalculation:boolean){
   const cartItemsWithAddOns = [];
   cartItems.forEach(cItem => {
     const modCartItem = { ...cItem }
     if (cItem?.all?.addonsCombo?.length) {
       cItem?.all?.addonsCombo.forEach(comboData => {
         const addOns = [];
         comboData.combo.forEach(group => {
           group.addOnProducts.forEach(addOnProduct => {
             if (addOnProduct.isAdded) {
               addOns.push(
                 {
                   "assetType": "addOnProduct",
                   "addOnGroupId": group._id,
                   "_id": addOnProduct._id,
                   "quantity": comboData.quantity
                 }
               )
             }
           })

         })
        //  if (disableTaxCalculation && modCartItem.all) {
        //    delete modCartItem.all
        //    }
          cartItemsWithAddOns.push({ ...modCartItem, addOns,uId:comboData.comboId,quantity: comboData.quantity,all:{...(modCartItem?.all || {}),quantity:comboData.quantity} });
       })
     } else {
       if (disableTaxCalculation && modCartItem.all) {
         delete modCartItem.all
       }
       cartItemsWithAddOns.push(modCartItem);
     }
   })
  return cartItemsWithAddOns
 }


}
