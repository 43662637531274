import {Injectable} from '@angular/core';
import {io, Socket} from 'socket.io-client';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {SocketData} from '../models/filter.model';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  connections: Record<string, Socket> = {};
  private url = environment.BASE_URL;

  constructor() {
  }

  get(nsp: string, topic: string) {
    return new Observable<SocketData>((observer) => {
      this._connect(nsp)?.on(topic, (data) => {
        observer.next(data);
      });
    });
  }

  private _connect(nsp = '') {
    const token = sessionStorage.getItem(`${sessionStorage.getItem('accountId')}_token`);
    if (!token) {
      return;
    }

    if (this.connections?.[nsp]?.connected) {
      return this.connections[nsp];
    } else {
      this.connections[nsp] = io(`${this.url}${nsp}`, {
        query: { token },
        autoConnect: true,
        transports: ['websocket'],
      });

      this.connections[nsp].on('connect', () => {
        console.log(nsp, 'connect');
      });

      this.connections[nsp].on('disconnect', () => {
        console.log(nsp, 'disconnected');
      });

      this.connections[nsp].on('connect_error', (e) => {
        console.error(`${nsp} ${e}`);
      });

      this.connections[nsp].on('error', (e) => {
        console.error(`${nsp} ${e}`);
      });
    }

    return this.connections[nsp];
  }

  getSocketConnection(nsp: string) {
    return this.connections[nsp];
  }


  _disconnect(nsp: string) {
    const socket = this.connections[nsp];
    if (socket) {
      socket.close();
    }
  }
}
